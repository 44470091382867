import React from 'react';
import BaseButton from './BaseButton';
import { IButtonProps } from './types';

export const PrimaryButton: React.FC<IButtonProps> = (props) => (
	<BaseButton
		{...props}
		className={`bg-secondary text-primary hover:opacity-90 disabled:bg-gray-300 ${
			props.className ?? ''
		}`}
		color="primary"
	/>
);

export const CancelButton: React.FC<IButtonProps> = (props) => (
	<BaseButton {...props} color="warning" variant="contained" />
);

export const DeleteButton: React.FC<IButtonProps> = (props) => (
	<BaseButton {...props} color="error" variant="contained" />
);

export const SaveButton: React.FC<IButtonProps> = (props) => (
	<BaseButton {...props} color="success" variant="contained" />
);

export const NextButton: React.FC<IButtonProps> = (props) => (
	<BaseButton {...props} color="info" variant="contained" />
);
