import DomainIcon from '@mui/icons-material/Domain';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import { Backdrop, ClickAwayListener, IconButton, Typography } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import GlobalSearch from 'components/GlobalSearch/GlobalSearch';
import { useGlobalStore } from 'global-state/useStore';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useLogout } from 'rq/hooks/authHook';
import { useOrganizationDetails } from 'rq/hooks/organizationsHook';

import Avatar from '@mui/material/Avatar';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AppBarProps } from './types';

import { Star } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import ActionIconButton from 'components/shared/Button/ActionIconButton';
import { displayUserFullName } from 'helper/helperFunctions';
import { useNavigate, useParams } from 'react-router-dom';
import { useSite } from 'rq/hooks/sitesHook';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import CustomBreadcrumbs from '../Breadcrumbs/CustomBreadcrumbs';
import OrganizationSwitcher from '../Modal/OrganizationSwitcher/OrganizationSwitcher';
import ProfileMenu from './ProfileMenu';
import SmallScreensMenu from './SmallScreensMenu';

const AppBarComponent = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	})
}));

const AppBar: FunctionComponent<AppBarProps> = (props) => {
	const { open, drawerToggle } = props;
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { siteId } = useParams();
	const theme = useTheme();

	const largeBreakpoint = useMediaQuery(theme.breakpoints.up(1024));
	const clickAwayClosesMenu = useMediaQuery(theme.breakpoints.down(768));
	const breadcrumbs = useReactRouterBreadcrumbs();
	breadcrumbs.splice(1, 1);

	const { data: user } = useAuth();
	const { mutate: logout } = useLogout();

	const currentOrganizationId = useGlobalStore((state) => state.currentOrganization.id);

	const { data: currentOrganization } = useOrganizationDetails(
		currentOrganizationId as unknown as string
	);

	const userImage = user?.image;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);

	const openUserMenu = Boolean(anchorEl);
	const openMenu = Boolean(anchorElMenu);

	const toggleGlobalSearchBar = useGlobalStore((state) => state.toggleGlobalSearchBar);
	const toggleOrganizationSwitchModal = useGlobalStore(
		(state) => state.toggleOrganizationSwitchModal
	);
	const organizationSwitcher = useGlobalStore((state) => state.organizationSwitcher);
	const toggleConfirmationDialog = useGlobalStore((state) => state.toggleConfirmationDialog);

	useEffect(() => {
		if (organizationSwitcher.toggled && organizationSwitcher.organizations?.length === 1) {
			const org = organizationSwitcher.organizations[0];
			toggleConfirmationDialog({
				dialogOpen: true,
				message: t('SHOW_SITE_DETAILS_PROMPT', { orgName: org.name }),
				type: 'confirmation',
				confirmActionLabel: t('SWITCH_ORG_AND_SHOW_SITE'),
				cancelActionLabel: t('CANCEL'),
				confirmAction: () => {
					localStorage.setItem('currentOrganizationId', org.id.toString());
					navigate(`/${org.id}/sites/${organizationSwitcher.siteId}/`);
				}
			});
			toggleOrganizationSwitchModal({ organizations: null, toggled: false, siteId: null });
		}
	}, [organizationSwitcher.toggled, organizationSwitcher.organizations]);
	const handleModalOpen = () =>
		toggleOrganizationSwitchModal({ toggled: true, organizations: null, siteId: null });
	const handleModalClose = () =>
		toggleOrganizationSwitchModal({ toggled: false, organizations: null, siteId: null });

	const logoutHandler = () => {
		logout();
	};

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleSmallScreenMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElMenu(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorElMenu(null);
	};

	const { data: siteDetails } = useSite(
		{
			site_id: Number(siteId)
		},
		{ enabled: !!siteId }
	);

	return (
		<>
			{clickAwayClosesMenu && (
				<Backdrop open={open !== undefined ? open : false} className={'z-10'} />
			)}
			<AppBarComponent open={open} className={'bg-secondary'} position={'relative'}>
				<Toolbar className=" flex h-max  justify-between">
					<ClickAwayListener
						onClickAway={() => {
							drawerToggle && open && clickAwayClosesMenu && drawerToggle();
						}}>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							className={'mr-4 text-primary'}
							onClick={drawerToggle}>
							{open ? <MenuOpenIcon /> : <MenuIcon />}
						</IconButton>
					</ClickAwayListener>
					<Typography className="flex grow" component="span">
						<CustomBreadcrumbs user={user} organization={currentOrganization} site={siteDetails} />
					</Typography>
					<div className={'flex gap-md'}>
						<div className={'flex flex-row items-center justify-self-end'}>
							{largeBreakpoint ? (
								<GlobalSearch />
							) : (
								<SearchIcon
									onClick={() =>
										toggleGlobalSearchBar({ toggled: true, searchType: 'in_organization' })
									}
									className={'text-primary'}
								/>
							)}
						</div>
						{largeBreakpoint ? (
							<>
								<Chip
									onClick={handleModalOpen}
									variant="outlined"
									className=" h-10 rounded-[20px] bg-primary px-1 text-base text-secondary hover:opacity-90"
									icon={
										currentOrganization?.logo ? (
											<Avatar className="mx-0  h-8 w-8" src={currentOrganization.logo} />
										) : (
											<Avatar className="mx-0 h-8 w-8 bg-secondary">
												<DomainIcon className={'text-primary'} />
											</Avatar>
										)
									}
									label={currentOrganization?.name}
								/>
								<Chip
									onClick={handleClick}
									variant="outlined"
									className="h-10 rounded-[20px] bg-primary px-1 text-sm text-secondary hover:opacity-90"
									icon={
										<Avatar
											className="mx-0  h-8 w-8 bg-secondary text-primary"
											alt="user"
											src={userImage}
										/>
									}
									label={
										<ActionIconButton
											title={
												user?.super_admin
													? t('YOU_ARE_SUPER_ADMIN')
													: (user && displayUserFullName(user)) ?? ''
											}
											icon={
												<div className={'flex-row items-center gap-sm align-middle text-inherit'}>
													{' '}
													{user && displayUserFullName(user)} {user?.super_admin && <Star />}
												</div>
											}
											className="font-inherit text-base text-inherit"
										/>
									}
								/>
								<ProfileMenu
									anchorEl={anchorEl}
									openUserMenu={openUserMenu}
									handleClose={handleClose}
									logoutHandler={logoutHandler}
								/>
							</>
						) : (
							<>
								<IconButton onClick={handleSmallScreenMenu}>
									<MoreVertIcon className="text-primary" />
								</IconButton>
								<SmallScreensMenu
									anchorEl={anchorElMenu}
									openMenu={openMenu}
									handleClose={handleMenuClose}
									logoutHandler={logoutHandler}
									handleModalOpen={handleModalOpen}
								/>
							</>
						)}
					</div>
				</Toolbar>
			</AppBarComponent>
			{organizationSwitcher.toggled && (
				<OrganizationSwitcher
					open={organizationSwitcher.toggled}
					handleModalClose={handleModalClose}
				/>
			)}
		</>
	);
};
export default AppBar;
