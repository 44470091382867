import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { CancelButton, PrimaryButton } from 'components/shared/Button/ActionButtons';
import { useGlobalStore } from 'global-state/useStore';

import { useTranslation } from 'react-i18next';

const Dialog = () => {
	const { t } = useTranslation();

	const confirmationDialog = useGlobalStore((state) => state.confirmationDialog);
	const toggleConfirmationDialog = useGlobalStore((state) => state.toggleConfirmationDialog);

	const confirmClick = () => {
		confirmationDialog?.confirmAction();
		closeModal();
	};

	const closeModal = () => {
		confirmationDialog?.cancelAction?.();
		toggleConfirmationDialog({
			dialogOpen: false,
			confirmAction: () => null,
			headerTitle: '',
			message: '',
			type: null
		});
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={confirmationDialog?.dialogOpen ?? false}
			onClose={closeModal}
			closeAfterTransition
			className="shadow-xl">
			<Fade in={confirmationDialog?.dialogOpen}>
				<Box className="absolute left-1/2 top-1/2 w-screen -translate-x-1/2 -translate-y-1/2 transform overflow-y-auto bg-primary shadow-xl shadow-gray-900 outline-none md:w-max md:max-w-[90%]">
					{confirmationDialog?.headerTitle && (
						<div className="flex w-full flex-row justify-center bg-secondary py-4 text-xl font-medium text-white">
							{confirmationDialog?.headerTitle}
						</div>
					)}
					<div className="flex flex-col justify-center gap-lg p-4 md:p-6">
						<div className="w-[25rem] max-w-[90%] justify-center self-center text-center">
							{confirmationDialog?.message}
						</div>
						<div className="flex flex-row justify-center gap-md">
							{confirmationDialog?.type === 'confirmation' && (
								<CancelButton
									onClick={closeModal}
									text={t(confirmationDialog?.cancelActionLabel?.toUpperCase() ?? t('NO'))}
								/>
							)}
							<PrimaryButton
								onClick={confirmClick}
								text={t(confirmationDialog?.confirmActionLabel?.toUpperCase() ?? t('YES'))}
							/>
						</div>
					</div>
				</Box>
			</Fade>
		</Modal>
	);
};

export default Dialog;
