import { CustomStation } from 'interfaces/models/CustomStationModel';
import { DischargeKeeperStationDetails } from 'interfaces/models/DischargeKeeperStationDetails';
import { DischargeStationCurve } from 'interfaces/models/DischargeStationCurve';
import { DischargeStationDetails } from 'interfaces/models/DischargeStationDetails';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { queryKeys } from 'rq/constants';
import StationService from 'services/StationService/StationService';

export const useStations = (
	siteId: number,
	options?: Omit<
		UseQueryOptions<
			(DischargeStationDetails | DischargeKeeperStationDetails | CustomStation)[],
			unknown,
			(DischargeStationDetails | DischargeKeeperStationDetails | CustomStation)[],
			[string, string]
		>,
		'queryKey' | 'queryFn'
	>
) => {
	return useQuery(
		[queryKeys.stations, `site-${siteId}`],
		() => StationService.getStations(siteId),
		{
			...options
		}
	);
};
export const useStationDetails = <Type>(
	siteId: number,
	station_id: number | undefined,
	options?: Omit<
		UseQueryOptions<Type, unknown, Type, [string, string, string]>,
		'queryKey' | 'queryFn'
	>
) => {
	return useQuery(
		[queryKeys.station_details, `site-id-${siteId}`, `station-id-${station_id}`],
		() => StationService.getStationDetails<Type>(siteId, station_id ?? 0),
		{
			...options
		}
	);
};

export function useCreateStation<Type>(
	options?: Omit<
		UseMutationOptions<Omit<Type, 'station_id'>, unknown, unknown, unknown>,
		'mutationFn'
	>
) {
	return useMutation(
		({ siteId, data }: { siteId: number; data: Omit<Type, 'station_id'> }) =>
			StationService.createStation(siteId, data),
		{
			...options
		}
	);
}

export function useUpdateStation<Type>(
	options?: Omit<
		UseMutationOptions<Omit<Type, 'station_id'>, unknown, unknown, unknown>,
		'mutationFn'
	>
) {
	return useMutation(
		({
			siteId,
			stationId,
			data
		}: {
			siteId: number;
			stationId: number;
			data: Omit<Type, 'station_id'>;
		}) => StationService.updateStation(siteId, stationId, data),
		{
			...options
		}
	);
}

export function useDeleteStation(
	options?: Omit<UseMutationOptions<void, unknown, unknown, unknown>, 'mutationFn'>
) {
	return useMutation(
		({ site_id, station_id }: { site_id: number; station_id: number }) =>
			StationService.deleteStation(site_id, station_id),
		{
			...options
		}
	);
}

export const useRatingCurves = (
	siteId: number,
	stationId: number,
	options?: Omit<
		UseQueryOptions<
			DischargeStationCurve[],
			unknown,
			DischargeStationCurve[],
			[string, string, string]
		>,
		'queryKey' | 'queryFn'
	>
) => {
	return useQuery(
		[queryKeys.rating_curves, `site-${siteId}`, `station-${stationId}`],
		() => StationService.getDischargeCurves({ site_id: siteId, station_id: stationId }),
		{
			...options
		}
	);
};

export function useCreateRatingCurve(
	options?: Omit<UseMutationOptions<DischargeStationCurve, unknown, unknown, unknown>, 'mutationFn'>
) {
	return useMutation(
		({
			siteId,
			stationId,
			data
		}: {
			siteId: number;
			stationId: number;
			data: DischargeStationCurve;
		}) =>
			StationService.createDischargeCurve({ site_id: siteId, station_id: stationId, curve: data }),
		{
			...options
		}
	);
}

export function useUpdateRatingCurve(
	options?: Omit<UseMutationOptions<DischargeStationCurve, unknown, unknown, unknown>, 'mutationFn'>
) {
	return useMutation(
		({
			siteId,
			stationId,
			data
		}: {
			siteId: number;
			stationId: number;
			ratingCurveId: number;
			data: DischargeStationCurve;
		}) =>
			StationService.updateDischargeCurve({ site_id: siteId, station_id: stationId, curve: data }),
		{
			...options
		}
	);
}

export function useDeleteRatingCurve(
	options?: Omit<UseMutationOptions<void, unknown, unknown, unknown>, 'mutationFn'>
) {
	return useMutation(
		({
			site_id,
			station_id,
			rating_curve_id
		}: {
			site_id: number;
			station_id: number;
			rating_curve_id: number;
		}) =>
			StationService.deleteDischargeCurve({
				site_id: site_id,
				station_id: station_id,
				curve_id: rating_curve_id
			}),
		{
			...options
		}
	);
}

export const useSnapshotConfigHistory = (
	siteId: number,
	station_id: number,
	options?: Omit<
		UseQueryOptions<string[], unknown, string[], [string, string, string]>,
		'queryKey' | 'queryFn'
	>
) => {
	return useQuery(
		[queryKeys.snapshot_config_history, `site-${siteId}`, `station-${station_id}`],
		() => StationService.getSnapshotConfig(siteId, station_id),
		{
			...options
		}
	);
};
