import CircularProgress from '@mui/material/CircularProgress';

const CircularLoadingFullHeight = () => {
	return (
		<>
			<div className={'flex h-screen w-full flex-col items-center justify-center text-secondary'}>
				<CircularProgress size={75} thickness={5} />
			</div>
		</>
	);
};
export default CircularLoadingFullHeight;
