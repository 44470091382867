import { StationTypes } from 'interfaces/models/StationTypes';

export const profileTypes = ['FREE', 'PARABOLIC', 'RECTANGULAR', 'TRAPEZOIDAL'];
export const stationTypes: StationTypes[] = [
	'DISCHARGE',
	'DISCHARGE_KEEPER',
	'CUSTOM_STATION',
	'WEATHER_STATION',
	'POLLUTION_KEEPER'
];

export const measurementAggregationTypes = ['all_measurements', 'hourly', 'daily'];
export const chartTypes = ['measurements', 'rating_curve'];

export const exportMeasurementsTypes = ['csv', 'xls', 'json', 'yaml', 'zrxp', 'wml', 'mp4'];
export const exportMeasurementsFields = [
	{ name: 'username', label: 'author' },
	{ name: 'date_time', label: 'date' },
	{ name: 'level', label: 'level' },
	{ name: 'velocity', label: 'velocity' },
	{ name: 'discharge', label: 'discharge' },
	{ name: 'displacement', label: 'displacement' }
];
export const measurementsFilterTypes: MeasurementFilter[] = [
	{
		filterName: 'username',
		filterType: 'string',
		operator: { label: '', operator: '', value: '' },
		filterValue: ''
	},
	{
		filterName: 'discharge',
		filterType: 'number',
		operator: { label: '', operator: '', value: '' },
		filterValue: ''
	},
	{
		filterName: 'velocity',
		filterType: 'number',
		operator: { label: '', operator: '', value: '' },
		filterValue: ''
	},
	{
		filterName: 'level',
		filterType: 'number',
		operator: { label: '', operator: '', value: '' },
		filterValue: ''
	},
	{
		filterName: 'bulk_velocity',
		filterType: 'number',
		operator: { label: '', operator: '', value: '' },
		filterValue: ''
	},
	{
		filterName: 'mean_surface_velocity',
		filterType: 'number',
		operator: { label: '', operator: '', value: '' },
		filterValue: ''
	},
	{
		filterName: 'wet_cross_sectional_area',
		filterType: 'number',
		operator: { label: '', operator: '', value: '' },
		filterValue: ''
	} /*,
	{
		filterName: 'date time',
		filterType: 'date',
		operator: { label: '', operator: '', value: '' },
		filterValue: ''
	}*/
];

export type MeasurementFilter = {
	filterName: string;
	filterType: MeasurementFilterType;
	operator: MeasurementNumericOperator;
	filterValue: string | number | Date;
};
export const numericFilterOperators = [
	{
		operator: '<',
		label: 'LOWER_THAN',
		value: 'lt'
	},
	{
		operator: '>',
		label: 'GREATER_THAN',
		value: 'gt'
	},
	{
		operator: '=',
		label: 'EQUAL',
		value: 'eq'
	},
	{
		operator: '<=',
		label: 'LOWER_THAN_EQUAL',
		value: 'le'
	},
	{
		operator: '>=',
		label: 'GREATER_THAN_EQUAL',
		value: 'ge'
	}
];
export const dateFilterOperators = [
	{
		operator: '<',
		label: 'LOWER_THAN',
		value: 'lt'
	},
	{
		operator: '>',
		label: 'GREATER_THAN',
		value: 'gt'
	},
	{
		operator: '=',
		label: 'EQUAL',
		value: 'eq'
	}
];

export type MeasurementFilterName =
	| ''
	| 'date'
	| 'username'
	| 'discharge'
	| 'velocity'
	| 'bulk_velocity'
	| 'mean_surface_velocity'
	| 'level'
	| 'displacement'
	| 'date_time'
	| 'wet_cross_sectional_area';

export type MeasurementFilterType = '' | 'string' | 'number' | 'boolean' | 'date' | 'select';

export type MeasurementNumericOperator = { operator: string; label: string; value: string };

export type ParsedFilter = {
	filter: string;
	value: string | number | Date;
};

export const roughnessCategories = [
	{
		name: 'RIVER_BED_SOLID',
		value: 40,
		displayValue: '40',
		group: 'NATURAL_STREAMS'
	},
	{
		name: 'RIVER_BED_PLANTS',
		value: 30,
		displayValue: '30-35',
		group: 'NATURAL_STREAMS'
	},
	{
		name: 'RIVER_BED_ROCKS',
		value: 30,
		displayValue: '30',
		group: 'NATURAL_STREAMS'
	},
	{
		name: 'RIVER_BED_MOBILE_ROCKS',
		value: 28,
		displayValue: '28',
		group: 'NATURAL_STREAMS'
	},
	{
		name: 'WILD_RIVER_ROCKS',
		value: 20,
		displayValue: '20-28',
		group: 'NATURAL_STREAMS'
	},
	{
		name: 'SOLID_MATERIAL_SMOOTH',
		value: 60,
		displayValue: '60',
		group: 'EARTH_CHANNELS'
	},
	{
		name: 'BED_SAND',
		value: 40,
		displayValue: '40-50',
		group: 'EARTH_CHANNELS'
	},
	{
		name: 'FINE_GRAVEL',
		value: 45,
		displayValue: '45',
		group: 'EARTH_CHANNELS'
	},
	{
		name: 'COARSE_GRAVEL',
		value: 35,
		displayValue: '35',
		group: 'EARTH_CHANNELS'
	},
	{
		name: 'ROUGH_ROCKS_PLASTERED',
		value: 25,
		displayValue: '25-35',
		group: 'EARTH_CHANNELS'
	},
	{
		name: 'MACHINED_ROUGH',
		value: 25,
		displayValue: '25-30',
		group: 'ROCK_CHANNEL'
	},
	{
		name: 'EXPLODED_ROCK_CHANNELS',
		value: 20,
		displayValue: '20-25',
		group: 'ROCK_CHANNEL'
	},
	{
		name: 'COARSE_ROCK_CHANNEL',
		value: 20,
		displayValue: '20',
		group: 'ROCK_CHANNEL'
	},
	{
		name: 'BRICKS_NICELY_DONE',
		value: 80,
		displayValue: '80',
		group: 'CHANNELS_WITH_ROCKS'
	},
	{
		name: 'CAREFULLY_BROKEN_BLOCKS',
		value: 70,
		displayValue: '70',
		group: 'CHANNELS_WITH_ROCKS'
	},
	{
		name: 'REGULAR_WALL_BRICKS',
		value: 60,
		displayValue: '60',
		group: 'CHANNELS_WITH_ROCKS'
	},
	{
		name: 'COARSE_BLOCKS',
		value: 50,
		displayValue: '50',
		group: 'CHANNELS_WITH_ROCKS'
	},
	{
		name: 'ROUGHLY_BUILD_SHORE',
		value: 45,
		displayValue: '45-50',
		group: 'CHANNELS_WITH_ROCKS'
	},
	{
		name: 'SMOOTH_STEEL_FORMS',
		value: 100,
		displayValue: '100',
		group: 'CEMENTED_CHANNELS'
	},
	{
		name: 'SMOOTH',
		value: 90,
		displayValue: '90-95',
		group: 'CEMENTED_CHANNELS'
	},
	{
		name: 'SMOOTH_WOODEN_FORMS',
		value: 65,
		displayValue: '65-70',
		group: 'CEMENTED_CHANNELS'
	},
	{
		name: 'CEMENT_ONLY_PRESSED',
		value: 60,
		displayValue: '60-65',
		group: 'CEMENTED_CHANNELS'
	},
	{
		name: 'OLD_CEMENT',
		value: 60,
		displayValue: '60',
		group: 'CEMENTED_CHANNELS'
	},
	{
		name: 'COARSE_CEMENT',
		value: 55,
		displayValue: '55',
		group: 'CEMENTED_CHANNELS'
	},
	{
		name: 'IRREGULAR_CEMENT',
		value: 50,
		displayValue: '50',
		group: 'CEMENTED_CHANNELS'
	},
	{
		name: 'MOLDED',
		value: 90,
		displayValue: '90',
		group: 'STEEL_CHANNEL'
	},
	{
		name: 'RIVETED_STEEL_PLATES',
		value: 65,
		displayValue: '65-70',
		group: 'STEEL_CHANNEL'
	},

	{
		name: 'SMOOTHLY_GRINDED',
		value: 100,
		displayValue: '100',
		group: 'TUNNEL_CEMENT_PIPES'
	},
	{
		name: 'NORMAL_CEMENT',
		value: 70,
		displayValue: '70-80',
		group: 'TUNNEL_CEMENT_PIPES'
	},
	{
		name: 'COARSE_OLD_CEMENT',
		value: 65,
		displayValue: '65-75',
		group: 'TUNNEL_CEMENT_PIPES'
	},
	{
		name: 'ROUGH_ROCK_CEMENT',
		value: 40,
		displayValue: '40-50',
		group: 'TUNNEL_CEMENT_PIPES'
	},
	{
		name: 'TUNNEL_ROUGH_ROCK',
		value: 28,
		displayValue: '28-35',
		group: 'TUNNEL_CEMENT_PIPES'
	}
];

export const curveTypes = ['quadratic', 'cubic', 'qh_pairs', 'exponential'];
export const chartColors = [
	{ borderColor: 'red', backgroundColor: 'red' },
	{ borderColor: 'blue', backgroundColor: 'blue' },
	{ borderColor: 'green', backgroundColor: 'green' },
	{ borderColor: 'orange', backgroundColor: 'orange' },
	{ borderColor: 'purple', backgroundColor: 'purple' },
	{ borderColor: 'darkyellow', backgroundColor: 'darkyellow' },
	{ borderColor: 'darkcyan', backgroundColor: 'darkcyan' },
	{ borderColor: 'darkmagenta', backgroundColor: 'darkmagenta' },
	{ borderColor: 'darkpink', backgroundColor: 'darkpink' },
	{ borderColor: 'darkbrown', backgroundColor: 'darkbrown' },
	{ borderColor: 'darkgray', backgroundColor: 'darkgray' }
];
