import { useGlobalStore } from 'global-state/useStore';
import { SiteAssociationCreateUpdateDestroy } from 'interfaces/models/SiteAssociationCreateUpdateDestroy';
import { SiteBasicModel } from 'interfaces/models/SiteBasicModel';
import { SiteDetailsModel } from 'interfaces/models/SiteDetailsModel';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { CreateSiteParams, GetSiteParams, GetSitesParams } from 'services/SitesService/interface';
import SitesService from '../../services/SitesService/SitesService';
import { queryKeys } from '../constants';
import { SiteModel, SitesPaginatedListModel } from '../interfaces/Sites';

export const useSitesPaginated = (
	data: GetSitesParams,
	options?: Omit<
		UseQueryOptions<SitesPaginatedListModel, unknown, SitesPaginatedListModel, any[]>,
		'queryKey' | 'queryFn'
	>
) => {
	const currentOrganizationId = useGlobalStore.getState().currentOrganization?.id;
	return useQuery(
		[
			queryKeys.sites_paginated,
			{
				orgId: currentOrganizationId,
				page: data.queryParams.page,
				page_size: data.queryParams.page_size,
				search: data.queryParams.search
			}
		],
		() => SitesService.getSitesPaginated(data),
		{
			...options
		}
	);
};

export const useGetAllSites = (
	options?: Omit<
		UseQueryOptions<SiteModel[], unknown, SiteModel[], (string | number | undefined)[]>,
		'queryKey' | 'queryFn'
	>
) => {
	const currentOrganizationId = useGlobalStore.getState().currentOrganization?.id;
	return useQuery([`org-${currentOrganizationId}`, queryKeys.sites], () =>
		SitesService.getAllSites()
	);
};

export const useSite = (
	data: GetSiteParams,
	options?: Omit<
		UseQueryOptions<SiteModel, unknown, SiteModel, (string | number)[]>,
		'queryKey' | 'queryFn'
	>
) => {
	return useQuery(
		[
			queryKeys.sites,
			queryKeys.site,
			data.site_id,
			data.queryParams?.sub ? 'true' : 'false',
			data.queryParams?.['last-measurements'] ? 'true' : 'false'
		],
		() => SitesService.getSite(data),
		{
			...options
		}
	);
};

export function useCreateSite(
	options?: Omit<UseMutationOptions<SiteModel, unknown, unknown, unknown>, 'mutationFn'>
) {
	return useMutation((data: CreateSiteParams) => SitesService.createSite(data), {
		...options
	});
}

export const useUpdateSite = <Type>(
	options?: Omit<UseMutationOptions<Type, unknown, unknown, unknown>, 'mutationFn'>
) => {
	return useMutation(
		(data: SiteDetailsModel | SiteBasicModel) => SitesService.updateSite<Type>(data),
		{
			...options
		}
	);
};

export const useGetLastMeasurements = (
	{ last_measurements, sub }: { last_measurements: boolean; sub: boolean },
	options?: Omit<
		UseQueryOptions<
			SiteDetailsModel[],
			unknown,
			SiteDetailsModel[],
			(string | number | undefined)[]
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const currentOrganizationId = useGlobalStore.getState().currentOrganization?.id;
	return useQuery([`org-${currentOrganizationId}`, queryKeys.last_measurements], () =>
		SitesService.getLastMeasurements({ last_measurements, sub })
	);
};

export function useMoveSite(
	options?: Omit<
		UseMutationOptions<SiteAssociationCreateUpdateDestroy, unknown, unknown, unknown>,
		'mutationFn'
	>
) {
	return useMutation(
		({ site_id, organization_id }: { site_id: number; organization_id: number }) =>
			SitesService.createAssociation({ site_id: site_id, organization_id: organization_id }),
		{
			...options
		}
	);
}

export function useRemoveAssociation(
	options?: Omit<UseMutationOptions<unknown, unknown, unknown, unknown>, 'mutationFn'>
) {
	return useMutation(
		({ site_id, organization_id }: { site_id: number; organization_id: number }) =>
			SitesService.getAssociations(site_id).then((res) => {
				const associationId = res.find((org) => org.organization_id === organization_id)?.id;
				associationId &&
					SitesService.removeAssociation({
						site_id: site_id as number,
						association_id: associationId
					});
			}),
		{
			...options
		}
	);
}
