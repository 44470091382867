import { useGlobalStore } from 'global-state/useStore';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from 'rq/hooks/authHook';

export const useOrganizationContext = () => {
	const { orgId } = useParams();
	const setCurrentOrganization = useGlobalStore((state) => state.setCurrentOrganization);
	const { data: user } = useAuth();

	let organizationId = orgId ? Number(orgId) : null;

	if (!organizationId || isNaN(organizationId)) {
		const storedOrgId = localStorage.getItem('currentOrganizationId');
		organizationId = storedOrgId ? Number(storedOrgId) : 1;
	}

	useEffect(() => {
		if (organizationId && !isNaN(organizationId) && user) {
			const organization = user.organizations?.find((org) => org.id === organizationId);
			if (organization) {
				setCurrentOrganization(organization, Number(user.id));
			}
		}
	}, [orgId, user]);

	return {
		organizationId: organizationId && !isNaN(organizationId) ? organizationId.toString() : ''
	};
};
