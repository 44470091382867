import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { Autocomplete, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import classNames from 'classnames';
import { useGlobalStore } from 'global-state/useStore';
import { SiteDetailsModel } from 'interfaces/models/SiteDetailsModel';
import { FunctionComponent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGlobalSearch } from 'rq/hooks/searchHook';
import { useSitesPaginated } from 'rq/hooks/sitesHook';
import useDebounce from 'rq/hooks/useDebounce';
import { SiteModel } from 'rq/interfaces/Sites';

const GlobalSearch: FunctionComponent = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const orgId = useGlobalStore((state) => state.currentOrganization.id);
	const toggleGlobalSearchBar = useGlobalStore((state) => state.toggleGlobalSearchBar);
	const globalSearchToggled = useGlobalStore((state) => state.globalSearchToggled);
	const currentOrganization = useGlobalStore((state) => state.currentOrganization);

	const [filter, setFilter] = useState('');

	const autoCompleteRef = useRef<HTMLInputElement>();

	const debouncedSearchQuery = useDebounce(filter);

	const toggleOrganizationSwitchModal = useGlobalStore(
		(state) => state.toggleOrganizationSwitchModal
	);
	const setSelectedSiteId = useGlobalStore((state) => state.setSelectedSiteId);

	const { data: sites, isFetching: sitesFetching } = useSitesPaginated(
		{
			queryParams: { search: debouncedSearchQuery, page: 1, page_size: 10 }
		},
		{
			keepPreviousData: true,
			enabled:
				debouncedSearchQuery.length > 2 &&
				globalSearchToggled.searchType !== 'everywhere' &&
				filter !== ''
		}
	);
	const { data: globalSites, isFetching: globalSitesFetching } = useGlobalSearch(
		{
			searchTerm: debouncedSearchQuery,
			entity: 'site'
		},
		{ enabled: debouncedSearchQuery.length > 2 && globalSearchToggled.searchType === 'everywhere' }
	);

	const theme = createTheme({
		components: {
			MuiAutocomplete: {
				styleOverrides: {
					noOptions: () => ({
						':hover': {
							backgroundColor: 'rgb(229 231 235)',
							cursor: 'pointer'
						}
					}),
					popper: {
						width: 'fit-content'
					},
					option: {
						'&:hover': {
							backgroundColor: 'rgb(229 231 235)',
							cursor: 'pointer'
						}
					}
				}
			}
		}
	});

	const handleSiteFromOtherOrgClick = (option: any) => {
		toggleGlobalSearchBar({ toggled: false, searchType: null });
		if (
			option.organizations.length === 1 &&
			option.organizations[0].id === currentOrganization.id
		) {
			setSelectedSiteId(option.id);
			navigate(`/${currentOrganization.id}/sites/${option.id}`, {
				state: { siteId: option.id }
			});
			toggleGlobalSearchBar({
				toggled: false,
				searchType: null
			});
		} else
			toggleOrganizationSwitchModal({
				organizations: option.organizations,
				toggled: true,
				siteId: option.id
			});
	};

	const searchEverywhereOption = () => {
		return sitesFetching || globalSitesFetching ? (
			<div className={'flex w-full justify-center'}>
				<CircularProgress />
			</div>
		) : (
			<div className={'flex flex-col gap-md'}>
				{sites?.results.length === 0 && <div>{`${t('NO_SITES')} ${t('WERE_FOUND')}`}</div>}
				<div
					className={'flex items-center font-bold text-black'}
					onClick={() => {
						toggleGlobalSearchBar({
							toggled: true,
							searchType: 'everywhere'
						});
					}}>
					<TravelExploreIcon className={'mr-2 text-accent'} />
					<div>{t('SEARCH_EVERYWHERE')}</div>
				</div>
			</div>
		);
	};

	return (
		<div className={'flex flex-row items-center'}>
			<ThemeProvider theme={theme}>
				<Autocomplete
					onChange={(e, value: SiteDetailsModel | null) => {
						setFilter(value?.name ?? '');
						if (globalSearchToggled.searchType !== 'everywhere' && !!value) {
							setSelectedSiteId(value.site_id);
							navigate(`/${orgId}/sites/${value.site_id}`);
							toggleGlobalSearchBar({
								toggled: false,
								searchType: null
							});
						}
					}}
					onClose={() => {
						toggleGlobalSearchBar({
							toggled: false,
							searchType: null
						});
					}}
					disableCloseOnSelect={false}
					isOptionEqualToValue={(option: any, value: any) => {
						// Compare by "id" or "site_id"
						if (!value) return false;
						const optionId = option.id || option.site_id;
						const valueId = value.id || value.site_id;
						return optionId === valueId;
					}}
					ListboxProps={{
						style: { maxHeight: '300px', overflow: 'auto' }
					}}
					ListboxComponent={(listboxProps) => (
						<ul {...listboxProps}>
							{listboxProps.children}
							{globalSearchToggled.searchType !== 'everywhere' && (
								<li className={'p-2'} key={'toggle_global_search'}>
									{searchEverywhereOption()}
								</li>
							)}
						</ul>
					)}
					options={
						globalSearchToggled.searchType !== 'everywhere' && filter.length > 2
							? sites?.results ?? []
							: (globalSites?.site.results as unknown as SiteModel[]) ?? []
					}
					getOptionLabel={(option: SiteDetailsModel) => {
						return option.name;
					}}
					noOptionsText={
						filter !== '' ? searchEverywhereOption() : t('SEARCH_TERM_MIN_CHARS', { value: 3 })
					}
					size={'small'}
					className={'mr-2 rounded-md bg-primary hover:opacity-90 lg:w-52 xl:w-72'}
					clearOnBlur={false}
					sx={{
						'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
							border: '0px'
						}
					}}
					renderOption={(props, option: any) => {
						return (
							<li className={'cursor-pointer p-2'} {...props} key={option.site_id + option.name}>
								<div
									onClick={() => {
										if (globalSearchToggled.searchType === 'everywhere')
											handleSiteFromOtherOrgClick(option);
										else {
											navigate(`/${orgId}/sites/${option.id}`);
											toggleGlobalSearchBar({
												toggled: false,
												searchType: null
											});
										}
									}}
									className="w-full">
									{option.name}
									{globalSearchToggled.searchType === 'everywhere' && (
										<div className={' flex flex-wrap text-xs font-bold '}>
											<>{t('ORGANIZATIONS')}:</>
											{option.organizations?.map((e: any, index: number) => {
												return (
													<span key={e.name + e.id} className={'ml-1 font-normal '}>
														{e.name}
														{index !== option.organizations.length - 1 && ','}
													</span>
												);
											})}
										</div>
									)}
								</div>
							</li>
						);
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							placeholder={t('SEARCH_SITES')}
							onFocus={(e) => {
								e.preventDefault();
							}}
							inputRef={autoCompleteRef}
							className={classNames({
								'mr-2 border-primary transition-all duration-500 lg:w-52 xl:w-72': true
							})}
							value={filter}
							onChange={(e) => {
								setFilter(e.target.value);
							}}
						/>
					)}
				/>
			</ThemeProvider>
		</div>
	);
};
export default GlobalSearch;
