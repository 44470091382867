import { IconButton } from '@mui/material';
import React from 'react';
import IconWithTooltip from '../Tooltip/IconWithTooltip';

interface ActionIconButtonProps {
	title: string;
	icon: React.ReactElement;
	onClick?: () => void;
	disabled?: boolean;
	className?: string;
	size?: 'small' | 'medium' | 'large';
}

const ActionIconButton: React.FC<ActionIconButtonProps> = ({
	title,
	icon,
	onClick,
	disabled = false,
	className = 'text-secondary',
	size = 'large' // Default to large size for consistency
}) => {
	// Clone the icon element to ensure consistent sizing
	const sizedIcon = React.cloneElement(icon, { fontSize: size });

	return (
		<div className="inline-flex">
			<IconWithTooltip
				title={title}
				icon={
					<IconButton
						onClick={onClick}
						disabled={disabled}
						size={size}
						className={`px-0 py-2 ${className} disabled:cursor-default disabled:text-gray-500 disabled:opacity-100`}>
						{sizedIcon}
					</IconButton>
				}
			/>
		</div>
	);
};

export default ActionIconButton;
