type FilterTypeDefinition = {
	filterName: string;
	filterType: 'string' | 'number' | 'date';
};

export const generateFilterTypes = (
	measurementType:
		| 'discharge_station'
		| 'discharge_keeper'
		| 'weather_station'
		| 'pollution_keeper'
		| 'custom_station'
): FilterTypeDefinition[] => {
	switch (measurementType) {
		case 'discharge_station':
		case 'discharge_keeper':
			return [
				{ filterName: 'username', filterType: 'string' },
				{ filterName: 'level', filterType: 'number' },
				{ filterName: 'velocity', filterType: 'number' },
				{ filterName: 'discharge', filterType: 'number' },
				{ filterName: 'mean_surface_velocity', filterType: 'number' },
				{ filterName: 'bulk_velocity', filterType: 'number' },
				{ filterName: 'wet_cross_sectional_area', filterType: 'number' }
			];
		case 'weather_station':
			return [
				{ filterName: 'temperature', filterType: 'number' },
				{ filterName: 'pressure', filterType: 'number' },
				{ filterName: 'humidity', filterType: 'number' },
				{ filterName: 'wind_speed', filterType: 'number' },
				{ filterName: 'wind_direction', filterType: 'number' },
				{ filterName: 'solar_radiation', filterType: 'number' },
				{ filterName: 'precipitation', filterType: 'number' },
				{ filterName: 'evaporation', filterType: 'number' }
			];
		case 'pollution_keeper':
			return [
				{ filterName: 'po4', filterType: 'number' },
				{ filterName: 'nh4', filterType: 'number' },
				{ filterName: 'turbidity', filterType: 'number' },
				{ filterName: 'cod', filterType: 'number' },
				{ filterName: 'intensity', filterType: 'number' },
				{ filterName: 'sensor_temperature', filterType: 'number' },
				{ filterName: 'sensor_humidity', filterType: 'number' },
				{ filterName: 'water_level', filterType: 'number' }
			];
		case 'custom_station':
			return [{ filterName: 'value', filterType: 'number' }];
		default:
			return [];
	}
};
