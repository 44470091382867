export const queryKeys = {
	me: 'me',
	organization: 'organization',
	organizations: 'organizations',
	organization_plan: 'organization_plan',
	public_organizations: 'public_organizations',
	user: 'user',
	users: 'users',
	sites_paginated: 'sites_paginated',
	sites: 'sites',
	site: 'site',
	last_measurements: 'last_measurements',
	alarms: 'alarms',
	globalSearch: 'global_search',
	stations: 'stations',
	station_details: 'station_details',
	measurements: 'measurements',
	measurements_paginated: 'measurements_paginated',
	rating_curves: 'rating_curves',
	rating_curve_measurements: 'rating_curve_measurements',
	snapshot_config_history: 'snapshot_config_history',
	processing_result: 'processing-result',
	processing_done: 'processing-done'
};
