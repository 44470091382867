import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DomainIcon from '@mui/icons-material/Domain';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { PrimaryButton } from 'components/shared/Button/ActionButtons';
import Modal from 'components/shared/Modal/Modal';
import PermissionView from 'components/shared/PermissionsView/PermissionView';
import { useGlobalStore } from 'global-state/useStore';
import { OrganizationListRetrieve } from 'interfaces/models/OrganizationListRetrieve';
import { PropsWithChildren, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { queryKeys } from 'rq/constants';
import { useAuth } from 'rq/hooks/authHook';
import { usePublicOrganizations } from 'rq/hooks/organizationsHook';
import { queryClient } from 'rq/queryClient';

import { Lock, LockOpen } from '@mui/icons-material';
import ActionIconButton from 'components/shared/Button/ActionIconButton';
import { getDefaultHomepage } from 'helper/helperFunctions';
import { OrganizationCreateUpdateDestroy } from 'interfaces/models/OrganizationCreateUpdateDestroy';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import CreateOrganizationModal from '../CreateOrganization/CreateOrganizationModal';
import { IModalProps } from './types';

const OrganizationSwitcher = (props: PropsWithChildren<IModalProps>) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { data: user } = useAuth();
	const { data: otherOrganizations, isLoading: otherOrgLoading } = usePublicOrganizations();
	const userOrganizations = user?.organizations ?? [];
	const organizationSwitcher = useGlobalStore((state) => state.organizationSwitcher);
	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);

	const [createOrganizationModalOpen, setCreateOrganizationModalOpen] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState('');

	const chooseAnOrganization = (org: OrganizationListRetrieve) => {
		props.handleModalClose();
		const defaultHomepage = getDefaultHomepage(org.id.toString(), user?.id?.toString() ?? '');
		navigate(defaultHomepage ?? `/${org.id.toString()}`, {
			state: { orgName: org.name }
		});
	};

	const createNewOrganization = () => {
		setCreateOrganizationModalOpen(true);
	};

	const closeCreateNewOrganizationModal = () => {
		setCreateOrganizationModalOpen(false);
	};

	const orgCreated = (org: OrganizationCreateUpdateDestroy) => {
		queryClient.invalidateQueries(queryKeys.me);
		navigate(`/${org.id}`);
		props.handleModalClose();
	};

	const OrganizationRow = ({ index, style, data }: any) => {
		const org = data[index];
		return (
			<div style={style}>
				<div
					className="flex cursor-pointer flex-row items-center justify-between p-2 hover:bg-gray-200"
					data-org-id={org.id}
					onClick={() => chooseAnOrganization(org)}
					key={org.id}>
					<div className={'flex gap-sm'}>
						<Avatar className="mx-0 mr-2 h-10 w-10 bg-blue-300" src={org.logo}>
							<DomainIcon />
						</Avatar>
						<div className="flex flex-col justify-center">
							<div>{org.name}</div>
						</div>
					</div>
					<div>
						<ActionIconButton
							size="medium"
							title={org.is_public ? t('PUBLIC_ORGANIZATION') : t('PRIVATE_ORGANIZATION')}
							icon={org.is_public ? <LockOpen /> : <Lock />}
						/>
					</div>
				</div>
			</div>
		);
	};

	const filteredUserOrgs = useMemo(
		() =>
			userOrganizations.filter((org) => org.name.toLowerCase().includes(searchTerm.toLowerCase())),
		[userOrganizations, searchTerm]
	);

	const filteredOtherOrgs = useMemo(
		() =>
			otherOrganizations?.filter(
				(publicOrg) =>
					!userOrganizations.some((userOrg) => publicOrg.id === userOrg.id) &&
					publicOrg.name.toLowerCase().includes(searchTerm.toLowerCase())
			) || [],
		[otherOrganizations, userOrganizations, searchTerm]
	);

	return (
		<Modal
			open={props.open}
			closeModal={props.handleModalClose}
			modalTitle={t('SWITCH_ORGANIZATION')}>
			<div className="flex h-[90vh] w-full flex-col p-4 md:min-w-[40rem] lg:min-w-[50rem]">
				{organizationSwitcher.organizations && (
					<div className={'flex justify-center'}>{t('SWITCH_ORG_TO_CONTINUE')}</div>
				)}

				<div
					className={
						'flex w-full flex-col-reverse gap-sm p-4 sm:flex-row sm:flex-wrap-reverse sm:justify-between'
					}>
					<div className="flex justify-center">
						<TextField
							value={searchTerm}
							variant={'outlined'}
							size={'small'}
							label={t('SEARCH_ORGS')}
							onChange={(e) => {
								setSearchTerm(e.target.value);
							}}
						/>
					</div>
					<div className={'sm:ml-auto'}>
						<PermissionView
							requiredPermissions={'manage_organization'}
							showFallbackComponent={false}>
							<PrimaryButton
								onClick={createNewOrganization}
								className={'flex w-full gap-sm sm:w-auto'}>
								<AddCircleOutlineIcon className={'text-white'} />
								<span>{t('CREATE_ORGANIZATION')}</span>
							</PrimaryButton>
						</PermissionView>
					</div>
				</div>

				<div className={'flex flex-1 flex-col gap-md overflow-hidden'}>
					<div className={'self-center font-bold'}>{t('USER_ORGANIZATIONS')}</div>
					<div className={'min-h-0 flex-1'}>
						{filteredUserOrgs.length > 0 ? (
							<AutoSizer>
								{({ height, width }) => (
									<List
										height={height}
										width={width}
										itemCount={filteredUserOrgs.length}
										itemSize={60}
										itemData={filteredUserOrgs}>
										{OrganizationRow}
									</List>
								)}
							</AutoSizer>
						) : (
							<div className={'self-center'}>{t('NO_RESULTS')}</div>
						)}
					</div>

					<div className={'self-center font-bold'}>{t('OTHER_ORGANIZATIONS')}</div>
					{otherOrgLoading ? (
						<div className={'flex w-full flex-col items-center justify-center p-10'}>
							<CircularProgress />
							<span>{t('LOADING')}</span>
						</div>
					) : (
						<div className={'min-h-0 flex-1'}>
							{filteredOtherOrgs.length > 0 ? (
								<AutoSizer>
									{({ height, width }) => (
										<List
											height={height}
											width={width}
											itemCount={filteredOtherOrgs.length}
											itemSize={60}
											itemData={filteredOtherOrgs}>
											{OrganizationRow}
										</List>
									)}
								</AutoSizer>
							) : (
								<div className={'self-center'}>{t('NO_RESULTS')}</div>
							)}
						</div>
					)}
				</div>
			</div>
			{createOrganizationModalOpen && (
				<CreateOrganizationModal
					open={createOrganizationModalOpen}
					closeModal={closeCreateNewOrganizationModal}
					afterCreatingOrganization={orgCreated}
				/>
			)}
		</Modal>
	);
};

export default OrganizationSwitcher;
