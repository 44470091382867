import { useOrganizationContext } from 'hooks/useOrganization';
import AuthPagesTheme from 'pages/Auth/AuthPagesTheme';
import { createBrowserRouter, Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from 'rq/hooks/authHook';
import App from '../App';
import CircularLoadingFullHeight from '../components/shared/LoadingIndicators/CircularLoadingFullHeight';
import { getDefaultHomepage } from '../helper/helperFunctions';
import { authRoutes } from './routeConfigurations/authRoutes';
import { privateRoutes } from './routeConfigurations/privateRoutes';

// Auth guard component
const RequireAuth = () => {
	const { data: isAuth, isLoading } = useAuth();
	const location = useLocation();
	const { organizationId } = useOrganizationContext();
	const defaultHomepage = getDefaultHomepage(organizationId ?? '', isAuth?.id?.toString() ?? '');

	if (isLoading) {
		return <CircularLoadingFullHeight />;
	}

	if (!isAuth) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	if (location.pathname === '/' || location.pathname === '') {
		return <Navigate to={defaultHomepage} replace />;
	}

	return <Outlet />;
};

const PublicRoute = () => {
	const { data: isAuth, isLoading } = useAuth();
	const location = useLocation();
	const { organizationId } = useOrganizationContext();
	const defaultHomepage = getDefaultHomepage(organizationId ?? '', isAuth?.id?.toString() ?? '');
	const from = location.state?.from?.pathname || `/${organizationId}`;

	if (isLoading) {
		return <CircularLoadingFullHeight />;
	}

	if (location.pathname === '/' || location.pathname === '') {
		return <Navigate to={defaultHomepage} replace />;
	}

	// If user is authenticated and we have a default homepage, redirect
	if (isAuth && !!defaultHomepage) {
		return <Navigate to={defaultHomepage !== from ? from : defaultHomepage} replace />;
	} else if (isAuth && location.pathname === '/login') {
		return <Navigate to={from} replace />;
	}

	return <Outlet />;
};

const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		children: [
			{
				element: <PublicRoute />,
				children: [
					{
						index: true,
						element: <Outlet />
					},
					{
						element: (
							<AuthPagesTheme>
								<Outlet />
							</AuthPagesTheme>
						),
						children: authRoutes
					}
				]
			},
			{
				element: <RequireAuth />,
				children: privateRoutes
			}
		]
	}
]);

export default router;
