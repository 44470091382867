import { apiClient } from 'agent/apiClient';
import { useGlobalStore } from 'global-state/useStore';
import { OrganizationLogo } from 'interfaces/models/OrganizationLogo';
import { queryKeys } from 'rq/constants';
import { queryClient } from 'rq/queryClient';
import AuthService from 'services/AuthService/AuthService';
import { checkOrgPermissionsForUser } from 'utils/functions';
import { IOrganizationService } from './interface';

export const OrganizationsService: IOrganizationService = {
	getLastMeasurements: async (data) => {
		return await apiClient
			.get(`sites/?last-measurements=${data.last_measurements}&sub=${data.sub}`)
			.then((response) => response.data);
	},
	switchOrganization: async (orgId: number) => {
		const response = await AuthService.getAuth().then((user) => {
			const currentOrganization = checkOrgPermissionsForUser(user, orgId);
			localStorage.setItem(`lastOrganization-${user?.id}`, `${orgId}`);
			localStorage.setItem('currentOrganizationId', `${orgId}`);
			return { user, currentOrganization };
		});
		queryClient.invalidateQueries([queryKeys.alarms, `org-id-${orgId}`]);
		queryClient.invalidateQueries(queryKeys.sites);

		useGlobalStore.setState({ currentOrganization: response.currentOrganization });
	},
	organizationCreate: async (data) => {
		return await apiClient.post('/organizations/', data).then((res) => {
			return res.data;
		});
	},
	getOrganizationDetails: async (organizationId) => {
		return await apiClient.get(`/organizations/${organizationId}/`).then((res) => {
			return res.data;
		});
	},
	organizationsList: async (isPublic, ordering) => {
		return await apiClient
			.get(`/organizations/`, {
				params: {
					...(isPublic && { is_public: isPublic }),
					...(ordering && { ordering: ordering })
				}
			})
			.then((res) => {
				return res.data;
			});
	},
	deleteOrganization: async (organizationId) => {
		return await apiClient.delete(`/delete/${organizationId}/`);
	},
	updateOrganization: async ({ organizationId, data }) => {
		return await apiClient.put(`/organizations/${organizationId}/`, data).then((res) => {
			return res.data;
		});
	},
	createOrganizationsMembership: async ({ organizationId, data }) => {
		return await apiClient
			.post(`/organizations/${organizationId}/memberships/`, data)
			.then((res) => {
				return res.data;
			});
	},
	getOrganizationMemberships: async (organizationId) => {
		return await apiClient
			.get(`/organizations/${organizationId}/memberships/`)
			.then((res) => res.data);
	},
	getOrganizationMembershipDetails: async ({ membershipId, organizationId }) => {
		return await apiClient
			.get(`/organizations/${organizationId}/memberships/${membershipId}/`)
			.then((res) => res.data);
	},
	organizationsPlanList: async (organizationId) => {
		return await apiClient.get(`/organizations/${organizationId}/plan/`).then((res) => {
			return res.data;
		});
	},
	updateOrganizationMemberships: async ({ membershipId, organizationId, data }) => {
		return await apiClient
			.put(`/organizations/${organizationId}/memberships/${membershipId}/`, data)
			.then((res) => res.data);
	},
	deleteOrganizationMembership: async ({ membershipId, organizationId }) => {
		return await apiClient.delete(`/organizations/${organizationId}/memberships/${membershipId}/`);
	},
	joinOrganization: async ({ userId, orgSelection }) => {
		const promises: Promise<{ affiliation_id: number }>[] = [];
		for (let i = 0; i < orgSelection.length; i++) {
			promises.push(
				OrganizationsService.createOrganizationsMembership({
					organizationId: orgSelection[i].toString(),
					data: {
						user: userId
					}
				})
			);
		}

		return Promise.allSettled(promises).then(async () => {
			return AuthService.getAuth().then((response) => {
				Promise.resolve();
				return response;
			});
		});
	},
	getOrganizationImage: async (orgId) => {
		return await apiClient
			.get<OrganizationLogo>(`organizations/${orgId}/logo/`)
			.then((res) => {
				Promise.resolve();
				return res.data;
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	},

	updateOrganizationImage: async (orgId, data) => {
		const formData = new FormData();
		formData.append('image', data ?? '');
		return await apiClient
			.put<OrganizationLogo>(`organizations/${orgId}/logo/`, formData, {
				headers: {
					'content-type': 'multipart/form-data'
				}
			})
			.then((res) => {
				Promise.resolve();
				return res.data;
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	},

	deleteOrganizationImage: async (orgId) => {
		return await apiClient
			.delete<void>(`organizations/${orgId}/logo/`)
			.then((res) => {
				Promise.resolve();
				return res.data;
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	}
};
