import { QueryClient } from 'react-query';

export function generateQueryClient(): QueryClient {
	return new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: 600000,
				cacheTime: 900000,
				refetchOnMount: true,
				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
				retry: (failureCount, error: any) => {
					if (error?.response?.status === 404) {
						return false; // don't retry on 404s
					}
					return failureCount < 1;
				}
			}
		}
	});
}

export const queryClient = generateQueryClient();
