import { Delete, Settings } from '@mui/icons-material';
import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { CancelButton, PrimaryButton } from 'components/shared/Button/ActionButtons';
import ActionIconButton from 'components/shared/Button/ActionIconButton';
import { useGlobalStore } from 'global-state/useStore';
import { useManageStationPermissions } from 'hooks/useManageStationPermissions';
import { MembershipPermissionCreateUpdate } from 'interfaces/models/MembershipPermissionCreateUpdate';
import {
	useCreateStation,
	useDeleteStation,
	useStations,
	useUpdateStation
} from 'rq/hooks/stationHook';

import classNames from 'classnames';
import PermissionView from 'components/shared/PermissionsView/PermissionView';
import { useFormik } from 'formik';
import {
	handleViewAlarmsClick,
	handleViewMeasurementsClick,
	isEqual
} from 'helper/helperFunctions';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { queryKeys } from 'rq/constants';
import { queryClient } from 'rq/queryClient';
import * as Yup from 'yup';
import ViewAlarmsButton from '../ViewAlarmsButton';
import ViewMeasurementsButton from '../ViewMeasurementsButton';
import { ICustomStationTabProps } from './types';

export const CustomStationTab: React.FC<ICustomStationTabProps> = ({
	stationDetails,
	station_id,
	stationType
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const currentOrganization = useGlobalStore((state) => state.currentOrganization);
	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);
	const toggleCreateStationModal = useGlobalStore((state) => state.toggleCreateStationModal);
	const toggleConfirmationDialog = useGlobalStore((state) => state.toggleConfirmationDialog);
	const setStationDataChanged = useGlobalStore((state) => state.setStationDataChanged);
	const stationTabValue = useGlobalStore((state) => state.stationTabValue);
	const setStationTabValue = useGlobalStore((state) => state.setStationTabValue);
	const registerForm = useGlobalStore((state) => state.registerForm);
	const unregisterForm = useGlobalStore((state) => state.unregisterForm);
	const toggleEditCustomConfig = useGlobalStore((state) => state.toggleEditCustomConfig);
	const { data: stations } = useStations(Number(selectedSiteId));
	const { mutate: createStation } = useCreateStation();
	const { mutate: updateStation } = useUpdateStation();
	const { mutate: deleteStation } = useDeleteStation();

	const manageStationPermission = useManageStationPermissions(
		currentOrganization.permissions as MembershipPermissionCreateUpdate
	);

	const handleStationDelete = () => {
		deleteStation(
			{ site_id: Number(selectedSiteId), station_id: Number(station_id) },
			{
				onSuccess: () => {
					toast.success(t('STATION_DELETED'));
				},
				onSettled: () => {
					queryClient.invalidateQueries([queryKeys.stations, `site-${selectedSiteId}`]);
					setStationTabValue(stationTabValue - 1);
				}
			}
		);
	};

	const validationSchema = Yup.object().shape({
		station_name: Yup.string().required(t('REQUIRED'))
	});

	const formik = useFormik({
		initialValues: {
			station_name: stationDetails?.station_name ?? '',
			station_measurement_unit: stationDetails?.station_measurement_unit ?? '',
			station_measurement_type: stationDetails?.station_measurement_type ?? 'TEXT'
		},
		validationSchema: validationSchema,
		onSubmit: (data) => {
			const dataForSubmit = {
				...data,
				station_type: stationType ?? stationDetails?.station_type,
				station_measurement_type: data.station_measurement_type ?? 'TEXT',
				station_measurement_unit: data.station_measurement_unit || null
			};

			station_id &&
				updateStation(
					{
						siteId: Number(selectedSiteId),
						stationId: Number(station_id),
						data: dataForSubmit
					},
					{
						onSuccess: () => {
							toast.success(t('STATION_UPDATED'));
							queryClient.invalidateQueries([
								queryKeys.station_details,
								`site-id-${selectedSiteId}`
							]);
							queryClient.invalidateQueries([queryKeys.stations, `site-${selectedSiteId}`]);
						}
					}
				);
			!station_id &&
				createStation(
					{
						siteId: Number(selectedSiteId),
						data: dataForSubmit
					},
					{
						onSuccess: () => {
							toast.success(t('STATION_CREATED'));
							toggleCreateStationModal();
						},
						onSettled: (data, error) => {
							if (!error) {
								queryClient
									.invalidateQueries([queryKeys.stations, `site-${selectedSiteId}`])
									.then(() => {
										setStationTabValue(stations?.length ?? 0);
									});
							} else {
								console.error('Station creation failed:', error);
							}
						}
					}
				);
		},
		enableReinitialize: true
	});

	useEffect(() => {
		registerForm('station', {
			isDirty: formik.dirty,
			reset: () => formik.resetForm()
		});
		return () => unregisterForm('station');
	}, [formik.dirty, registerForm, unregisterForm]);

	useEffect(() => {
		setStationDataChanged(!isEqual(formik.values, formik.initialValues));
	}, [formik.values, formik.initialValues]);

	return (
		<form onSubmit={formik.handleSubmit} autoComplete={'off'}>
			<div className={'flex flex-row justify-between'}>
				<div className={' text-sm italic'}>{t('CUSTOM_STATION_STATION_DESCRIPTION')}</div>
				{station_id && (
					<div className={'flex items-center gap-xs align-middle'}>
						<div className={'flex w-full justify-end text-xs'}>
							{t('ID')}: {station_id}
						</div>
						<ActionIconButton
							title={`${t('DELETE')} ${t('STATION').toLowerCase()}`}
							icon={<Delete className={'text-danger'} />}
							size="medium"
							onClick={() => {
								toggleConfirmationDialog({
									confirmAction: handleStationDelete,
									dialogOpen: true,
									type: 'confirmation',
									message: t('DELETE_PROMPT') + '?'
								});
							}}
						/>
					</div>
				)}
			</div>
			<div className={'flex gap-sm'}>
				{station_id && (
					<ViewMeasurementsButton
						handleClick={() =>
							handleViewMeasurementsClick(
								navigate,
								currentOrganization.id,
								Number(selectedSiteId),
								Number(station_id)
							)
						}
					/>
				)}
				{station_id && (
					<ViewAlarmsButton
						handleClick={() =>
							handleViewAlarmsClick(
								navigate,
								currentOrganization.id,
								Number(selectedSiteId),
								Number(station_id)
							)
						}
					/>
				)}
			</div>
			<div className={'mt-4 flex flex-col gap-lg'}>
				<div className={'flex w-full flex-col lg:flex-row'}>
					<div className={'flex items-center align-middle max-md:text-lg max-md:font-bold md:w-60'}>
						{t('NAME')}
					</div>

					<TextField
						name={'station_name'}
						className={'w-full max-w-[15rem]'}
						size={'small'}
						onChange={formik.handleChange}
						value={formik.values.station_name}
						onBlur={formik.handleBlur}
						error={formik.touched.station_name && Boolean(formik.errors.station_name)}
					/>
				</div>
				<FormControl>
					<div className={'flex w-full flex-col lg:flex-row'}>
						<div
							className={'flex items-center align-middle max-md:text-lg max-md:font-bold md:w-60'}>
							{t('MEASUREMENT_TYPE')}
						</div>

						<RadioGroup
							aria-labelledby="station-measurement-type-radio-buttons-group-label"
							defaultValue={formik.values.station_measurement_type}
							name="station_measurement_type"
							onChange={formik.handleChange}
							row={true}>
							<FormControlLabel value="TEXT" control={<Radio />} label={t('TEXT')} />
							<FormControlLabel value="NUMERIC" control={<Radio />} label={t('NUMERIC')} />
						</RadioGroup>
					</div>
				</FormControl>
				<div className={'flex w-full flex-col lg:flex-row'}>
					<div className={'flex items-center align-middle max-md:text-lg max-md:font-bold md:w-60'}>
						{t('UNIT')}
					</div>

					<TextField
						name={'station_measurement_unit'}
						className={'w-full max-w-[15rem]'}
						size={'small'}
						onChange={formik.handleChange}
						value={formik.values.station_measurement_unit}
						onBlur={formik.handleBlur}
						error={
							formik.touched.station_measurement_unit &&
							Boolean(formik.errors.station_measurement_unit)
						}
					/>
				</div>
				<PermissionView requiredPermissions={['manage_sites']} showFallbackComponent={false}>
					<div className={'flex w-full flex-col lg:flex-row'}>
						<div
							className={'flex items-center align-middle max-md:text-lg max-md:font-bold md:w-60'}>
							{t('CUSTOM_CONFIGURATION')}
						</div>

						<>
							<div
								className={classNames(
									{ 'flex items-center gap-sm align-middle': true },
									{ 'cursor-pointer hover:opacity-50': !!stationDetails }
								)}>
								<Settings
									className={'text-gray-500'}
									onClick={() => station_id && toggleEditCustomConfig(true, Number(station_id))}
								/>
								<span className={'cursor-auto'}>{t('CONFIGURE')}</span>{' '}
								{/*		<Help className={'text-gray-500'}></Help>*/}
							</div>
						</>
					</div>
				</PermissionView>

				<div className={'flex w-full items-center justify-center gap-md'}>
					<CancelButton
						onClick={() => formik.resetForm()}
						className={'w-fit'}
						disabled={!formik.dirty || !formik.isValid || !manageStationPermission}>
						{t('RESET')}
					</CancelButton>
					<PrimaryButton
						type={'submit'}
						disabled={!formik.dirty || !formik.isValid || !manageStationPermission}>
						{station_id ? t('UPDATE') : t('CREATE')}
					</PrimaryButton>{' '}
				</div>
			</div>
		</form>
	);
};
