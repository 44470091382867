import CircularLoadingFullHeight from 'components/shared/LoadingIndicators/CircularLoadingFullHeight';
import { useGlobalStore } from 'global-state/useStore';
import { getDefaultHomepage } from 'helper/helperFunctions';
import { useOrganizationContext } from 'hooks/useOrganization';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { LazyComponentWrapper } from 'routing/RouteWrappers';
import { useAuth } from 'rq/hooks/authHook';
import { OrganizationListRetrieve } from 'rq/interfaces/Organization';
import { OrganizationsService } from 'services/OrganizationsService/OrganizationService';
import { cloudProcessingRoutes } from './cloudProcessingRoutes';

const OrganizationPage = React.lazy(() => import('pages/Organization/OrganizationPage'));
const ProfilePage = React.lazy(() => import('pages/ProfilePage/ProfilePage'));
const UsersPage = React.lazy(() => import('pages/Users/UsersPage'));
const Alarms = React.lazy(() => import('pages/Alarms/Alarms'));
const MapPage = React.lazy(() => import('pages/Map/MapPage'));
const SitesPage = React.lazy(() => import('pages/Sites/SitesPage'));
const SiteDetailsPage = React.lazy(() => import('pages/SiteDetails/SiteDetails'));
const MeasurementsPage = React.lazy(() => import('pages/MeasurementsPage/MeasurementsPage'));

//Organization wrapper to check if user has access to the organization
const OrganizationWrapper = ({ children }: { children: React.ReactNode }) => {
	const [loading, setLoading] = useState(true);
	const location = useLocation();
	const locationState = location.state as { orgName: string; siteId: number } | undefined;
	const params = useParams();
	const navigate = useNavigate();
	const toggleJoinOrRedirectOrganizationModal = useGlobalStore(
		(state) => state.toggleJoinOrRedirectOrganizationModal
	);
	const { data: user } = useAuth();
	const { organizationId } = useOrganizationContext();
	const currentOrganizationId = useGlobalStore((state) => state.currentOrganization?.id);
	const checkOrganizationAccess = async () => {
		setLoading(true);
		const paramId = Number(params.orgId);
		const defaultHomepage = getDefaultHomepage(paramId.toString(), user?.id?.toString() ?? '');

		if (isNaN(paramId)) {
			navigate(defaultHomepage);
			return;
		}
		const hasAccess =
			user?.super_admin ||
			user?.organizations?.some((org: OrganizationListRetrieve) => org.id === paramId);
		if (hasAccess) {
			if (Number(currentOrganizationId) !== paramId) {
				await OrganizationsService.switchOrganization(paramId);
				if (params.siteId || locationState?.siteId) {
					navigate(`/${organizationId}/sites/${params.siteId || locationState?.siteId}`, {
						replace: true
					});
				}
			} else {
				if (location.pathname === `/${organizationId}` && !location.search && !location.hash) {
					if (location.pathname !== defaultHomepage && defaultHomepage) {
						navigate(defaultHomepage);
					}
				}
			}
		} else {
			toggleJoinOrRedirectOrganizationModal(true, paramId, locationState?.orgName || '', true);
		}
		setLoading(false);
	};

	useEffect(() => {
		checkOrganizationAccess();
	}, [params.orgId, params.siteId]);

	if (loading)
		return (
			<div className="h-screen w-screen">
				<CircularLoadingFullHeight />
			</div>
		);

	return <>{children}</>;
};

export const organizationRoutes = [
	{
		element: (
			<OrganizationWrapper>
				<Outlet />
			</OrganizationWrapper>
		),
		children: [
			{
				path: ':orgId',
				children: [
					{
						index: true,
						element: (
							<LazyComponentWrapper>
								<Outlet />
							</LazyComponentWrapper>
						)
					},
					{
						path: 'organization',
						element: (
							<LazyComponentWrapper>
								<OrganizationPage />
							</LazyComponentWrapper>
						)
					},
					{
						path: 'users',
						element: (
							<LazyComponentWrapper>
								<UsersPage />
							</LazyComponentWrapper>
						)
					},
					{
						path: 'users/:userId',
						element: (
							<LazyComponentWrapper>
								<ProfilePage />
							</LazyComponentWrapper>
						)
					},
					{
						path: 'alarms',
						element: (
							<LazyComponentWrapper>
								<Alarms />
							</LazyComponentWrapper>
						)
					},
					{
						path: 'map',
						element: (
							<LazyComponentWrapper>
								<MapPage />
							</LazyComponentWrapper>
						)
					},
					{
						path: 'sites',
						element: (
							<LazyComponentWrapper>
								<SitesPage isCloudProcessing={false} />
							</LazyComponentWrapper>
						)
					},
					{
						path: 'sites/:siteId',
						element: (
							<LazyComponentWrapper>
								<SiteDetailsPage />
							</LazyComponentWrapper>
						)
					},
					{
						path: 'measurements',
						element: (
							<LazyComponentWrapper>
								<MeasurementsPage />
							</LazyComponentWrapper>
						)
					},
					{
						path: 'measurements/:siteId/:stationId',
						element: (
							<LazyComponentWrapper>
								<MeasurementsPage />
							</LazyComponentWrapper>
						)
					},
					...cloudProcessingRoutes
				]
			}
		]
	}
];
