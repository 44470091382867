import { displayUserFullName } from 'helper/helperFunctions';
import { UserListRetrieve } from 'interfaces/models/UserListRetrieve';

interface BreadcrumbConfig {
	path: string;
	breadcrumb: string | ((params: any) => string | React.ReactNode);
	navigateTo?: string; // Optional override for navigation
	// Function to generate dynamic navigation path
	getNavigationPath?: (params: {
		orgId: string;
		siteId?: string;
		stationId?: string;
		measurementId?: string;
		userId?: string;
	}) => string;
}

import i18n from 'i18next';

const breadcrumbConfigs: BreadcrumbConfig[] = [
	{
		path: '/',
		breadcrumb: i18n.t('HOME')
	},
	{
		path: '/cloudprocessing',
		breadcrumb: i18n.t('CLOUD_PROCESSING')
	},
	{
		path: '/cloudprocessing/:siteId',
		breadcrumb: i18n.t('SITE'),
		// When clicking site in cloud processing, go to site details
		getNavigationPath: ({ orgId, siteId }) => `/${orgId}/sites/${siteId}`
	},
	{
		path: '/cloudprocessing/:siteId/:stationId',
		breadcrumb: i18n.t('STATION'),
		getNavigationPath: ({ orgId, siteId, stationId }) => `/${orgId}/sites/${siteId}`
	},
	{
		path: '/sites',
		breadcrumb: i18n.t('SITES')
	},
	{
		path: '/sites/:siteId',
		breadcrumb: i18n.t('SITE_DETAILS')
	},
	{
		path: '/users',
		breadcrumb: 'Users'
	},
	{
		path: '/users/:userId',
		// Use a function to dynamically generate the breadcrumb text
		breadcrumb: (params: {
			match: { params: { userId: string } };
			location: { state?: { user?: UserListRetrieve } };
		}) => {
			const user = params.location.state?.user;

			if (user) {
				return displayUserFullName(user);
			}
			return `${i18n.t('USER')} ${params.match.params.userId}`;
		}
	}
];

export default breadcrumbConfigs;
