import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useGlobalStore } from 'global-state/useStore';
import toast from 'react-hot-toast';
import i18n from 'res/localization/i18n';
import AuthService from 'services/AuthService/AuthService';

let isRefreshing = false;
let failedQueue: {
	resolve: (value?: unknown) => void;
	reject: (reason?: any) => void;
}[] = [];

// Process failed queue with new token
const processQueue = (error: any = null) => {
	failedQueue.forEach((promise) => {
		if (error) {
			promise.reject(error);
		} else {
			promise.resolve();
		}
	});
	failedQueue = [];
};

export const apiClient = axios.create({
	baseURL: `${process.env.REACT_APP_API_DOMAIN}/${process.env.REACT_APP_API_PATH}`,
	headers: {
		'Content-type': 'application/json'
	}
});

apiClient.interceptors.request.use(
	function (config) {
		if (config.headers) {
			const accessToken = localStorage.getItem('access_token');
			const currentOrganization = useGlobalStore.getState().currentOrganization;

			if (!config.headers.Authorization && accessToken) {
				config.headers.Authorization = `Bearer ${accessToken}`;
			}

			if (
				!config.headers.Organization &&
				currentOrganization?.id &&
				config.data !== 'globalUsersGet'
			) {
				config.headers.Organization = Number(currentOrganization?.id);
			}
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

apiClient.interceptors.response.use(
	(response) => response,
	async (error) => {
		if (!error.response) return Promise.reject(error);

		const status = error.response.status;

		// Handle 504 Gateway Timeout specifically
		if (status === 504) {
			toast.error(i18n.t('REQUEST_TIMEOUT'));
			return Promise.reject(error);
		}

		if (![401, 403].includes(status)) {
			Sentry.captureException(error);
		}

		const originalRequest = error.config;
		const accessToken = localStorage.getItem('access_token');
		const refreshToken = localStorage.getItem('refresh_token');

		const isLoginRelatedRequest =
			originalRequest.url?.includes('/login') || window.location.pathname === '/login';

		if (isLoginRelatedRequest && status === 401) {
			handleCustomError(error.response?.data);
			return Promise.reject(error);
		}

		if ((!accessToken && refreshToken) || (status === 401 && !originalRequest._retry)) {
			if (isRefreshing) {
				return new Promise((resolve, reject) => {
					failedQueue.push({ resolve, reject });
				})
					.then(() => {
						originalRequest.headers.Authorization = `Bearer ${localStorage.getItem(
							'access_token'
						)}`;
						return apiClient(originalRequest);
					})
					.catch((err) => Promise.reject(err));
			}

			originalRequest._retry = true;
			isRefreshing = true;

			try {
				const { access_token, refresh_token } = await AuthService.refreshToken();
				localStorage.setItem('access_token', access_token);
				localStorage.setItem('refresh_token', refresh_token);

				originalRequest.headers.Authorization = `Bearer ${access_token}`;
				processQueue();
				return apiClient(originalRequest);
			} catch (refreshError) {
				processQueue(refreshError);
				await AuthService.logout();
				window.location.href = '/login';
				return Promise.reject(refreshError);
			} finally {
				isRefreshing = false;
			}
		}

		if (error.response?.data !== undefined && status !== 504) {
			handleCustomError(error.response.data);
		} else {
			handleCustomError(null);
		}
		return Promise.reject(error);
	}
);

function handleCustomError(errorData: any) {
	if (!errorData) {
		toast.error(i18n.t('SOMETHING_WENT_WRONG'));
		return;
	}

	try {
		// Handle form validation errors
		if (errorData.form_errors) {
			Object.entries(errorData.form_errors).forEach(([key, value]: [string, any]) => {
				if (Array.isArray(value)) {
					toast.error(value.join(', '));
				} else if (typeof value === 'string') {
					toast.error(value);
				}
			});
			return;
		}
		if (errorData.details) {
			toast.error(errorData.details);
		} else if (errorData.message) {
			toast.error(errorData.message);
		} else if (typeof errorData === 'string') {
			toast.error(errorData);
		} else {
			toast.error(i18n.t('SOMETHING_WENT_WRONG'));
		}
	} catch (e) {
		toast.error(i18n.t('SOMETHING_WENT_WRONG'));
	}
}
