import { PrimaryButton } from 'components/shared/Button/ActionButtons';
import Modal from 'components/shared/Modal/Modal';
import { useGlobalStore } from 'global-state/useStore';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { queryKeys } from 'rq/constants';
import { useAuth } from 'rq/hooks/authHook';
import { queryClient } from 'rq/queryClient';
import { OrganizationsService } from 'services/OrganizationsService/OrganizationService';

const OrganizationJoinOrRedirect = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { organizationId } = useGlobalStore((state) => state.joinOrRedirectOrganizationModal);

	const { open, orgName } = useGlobalStore((state) => state.joinOrRedirectOrganizationModal);
	const toggleJoinOrRedirectOrganizationModal = useGlobalStore(
		(state) => state.toggleJoinOrRedirectOrganizationModal
	);

	const { data: user } = useAuth();

	const [id] = useState<number>(Number(organizationId));

	const handleModalClose = () => {
		toggleJoinOrRedirectOrganizationModal(false, null, '', false);
	};

	const redirectHandler = () => {
		handleModalClose();
		navigate(`/${id}`);
	};

	const joinOrganizationsHandler = () => {
		user &&
			OrganizationsService.joinOrganization({
				userId: Number(user.id),
				orgSelection: [Number(organizationId)]
			}).then(() => {
				queryClient.invalidateQueries(queryKeys.me);
				toast.success(t('JOINED_ORG'));
				OrganizationsService.switchOrganization(Number(organizationId));
				handleModalClose();
			});
	};

	return (
		<Modal open={open} closeModal={handleModalClose} modalTitle={t('SWITCH_ORGANIZATION')}>
			<div className={'flex flex-col gap-md p-8'}>
				<div>{t('ORGANIZATION_ACCESS_DENIED', { orgName: orgName })}</div>
				<div className={'flex justify-center gap-md'}>
					<PrimaryButton onClick={joinOrganizationsHandler}> {t('JOIN')}</PrimaryButton>
					<PrimaryButton onClick={redirectHandler}> {t('REDIRECT')}</PrimaryButton>
				</div>
			</div>
		</Modal>
	);
};
export default OrganizationJoinOrRedirect;
