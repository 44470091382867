import { Home, HomeOutlined, NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGlobalStore } from 'global-state/useStore';
import { displayBreadCrumbText, getDefaultHomepage } from 'helper/helperFunctions';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import breadcrumbConfigs from 'routes/breadcrumbConfig';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';

interface CustomBreadcrumbsProps {
	user: any;
	organization?: any;
	site?: any;
}

const CustomBreadcrumbs: FC<CustomBreadcrumbsProps> = ({ user, organization, site }) => {
	const theme = useTheme();
	const { t } = useTranslation();
	const location = useLocation();
	const largeBreakpoint = useMediaQuery(theme.breakpoints.up(1024));
	const selectedStation = useGlobalStore((state) => state.cloudProcessing.selectedStation);
	const navigate = useNavigate();
	const params = useParams();

	const breadcrumbs = useReactRouterBreadcrumbs();
	// Remove the second element as it's redundant
	breadcrumbs.splice(1, 1);

	const lastPath = breadcrumbs[breadcrumbs.length - 1]?.match.pathname;

	// Get path without organization ID
	const getPathWithoutOrgId = (fullPath: string) => {
		const segments = fullPath.split('/');
		// Remove first empty string and orgId
		return segments.slice(2).join('/');
	};

	const currentPath = getPathWithoutOrgId(location.pathname);
	const isCurrentPathHomepage = getDefaultHomepage(undefined, user?.id) === `/${currentPath}`;
	const [homepage, setHomepage] = useState(
		JSON.parse(localStorage.getItem('userHomepages') || '{}')[user?.id]
	);

	const handleBreadcrumbClick = (pathname: string, e: React.MouseEvent) => {
		e.preventDefault();

		const config = breadcrumbConfigs.find((config) => matchPath(config.path, pathname));

		if (config?.getNavigationPath) {
			const navigationPath = config.getNavigationPath({
				orgId: organization.id,
				siteId: params.siteId,
				stationId: params.stationId,
				measurementId: params.measurementId,
				userId: user.id
			});
			navigate(navigationPath);
		} else if (config?.navigateTo) {
			navigate(config.navigateTo);
		} else {
			navigate(pathname);
		}
	};

	return (
		<div className="flex items-center gap-2">
			<Breadcrumbs
				separator={<NavigateNext className="text-xs text-primary lg:text-lg" />}
				maxItems={largeBreakpoint ? 4 : 2}
				aria-label="breadcrumb">
				{breadcrumbs.map(({ match, breadcrumb }, index) => {
					const isLastItem = index === breadcrumbs.length - 1;

					return (
						<div key={index} className="flex max-w-[8rem] items-center truncate lg:max-w-max">
							{isLastItem ? (
								// Last item - not clickable
								<span className="text-xs font-bold text-primary md:text-sm lg:text-lg">
									{displayBreadCrumbText(
										match.pathname,
										user,
										organization,
										site,
										selectedStation
									) ?? breadcrumb}
								</span>
							) : (
								// Clickable breadcrumb for non-last items
								<NavLink
									to={match.pathname}
									onClick={(e) => handleBreadcrumbClick(match.pathname, e)}
									className="text-xs font-bold text-primary hover:underline md:text-sm lg:text-lg">
									{displayBreadCrumbText(
										match.pathname,
										user,
										organization,
										site,
										selectedStation
									) ?? breadcrumb}
								</NavLink>
							)}
							{isLastItem && (
								<Tooltip
									title={isCurrentPathHomepage ? t('CURRENT_HOMEPAGE') : t('SET_AS_HOMEPAGE')}>
									<IconButton
										size="small"
										onClick={() => {
											const pathWithoutOrg = getPathWithoutOrgId(location.pathname);
											const userHomepages = JSON.parse(
												localStorage.getItem('userHomepages') || '{}'
											);
											userHomepages[user?.id || ''] = pathWithoutOrg;
											if (JSON.stringify(userHomepages) !== localStorage.getItem('userHomepages')) {
												localStorage.setItem('userHomepages', JSON.stringify(userHomepages));
												setHomepage(pathWithoutOrg);
											} else {
												localStorage.removeItem('userHomepages');
												setHomepage(null);
											}
										}}
										className="ml-1">
										{homepage === currentPath ? (
											<Home className="text-primary" fontSize="small" />
										) : (
											<HomeOutlined className="text-primary" fontSize="small" />
										)}
									</IconButton>
								</Tooltip>
							)}
						</div>
					);
				})}
			</Breadcrumbs>
		</div>
	);
};

export default CustomBreadcrumbs;
