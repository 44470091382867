import { useGlobalStore } from 'global-state/useStore';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { LoginData } from 'services/AuthService/interface';
import { checkOrgIdAndPermissions } from 'utils/functions';
import AuthService from '../../services/AuthService/AuthService';
import { queryKeys } from '../constants';
import { UserListRetrieve } from '../interfaces/User';

export const useAuth = () => {
	return useQuery<UserListRetrieve | null>(queryKeys.me, () => AuthService.getAuth(), {
		retry: false,
		onSuccess: (user) => {
			if (!user) return;
			const { id, permissions, name } = checkOrgIdAndPermissions(user, user?.id);

			useGlobalStore.setState({
				currentOrganization: {
					id: id,
					permissions: permissions,
					name: name
				}
			});
		},
		onError: async (error: any) => {
			if (!error?.isRefreshError) {
				await AuthService.logout();
			}
		}
	});
};

export const useLogin = () => {
	return useMutation((data: LoginData) => AuthService.login(data));
};

export const useLogout = () => {
	const navigate = useNavigate();
	return useMutation(() => AuthService.logout(), {
		onSuccess: () => {
			navigate('/login', { replace: true });
		}
	});
};
